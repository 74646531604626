<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form
      ref="form"
      :inline="true"
      :model="formInline"
      small="size"
      label-width="90px"
    >
      <el-form-item label="咨询时间：">
        <el-date-picker
          v-model="formInline.consultationTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        />
      </el-form-item>
      <!-- <el-row>
        <el-col :span="12"> -->
      <el-form-item label="患者姓名：">
        <el-input
          v-model.trim="formInline.patientName"
          v-check-permission
          placeholder="请输入患者姓名"
        />
      </el-form-item>
      <!-- </el-col>
        <el-col :span="6"> -->
      <el-form-item label="订单号：">
        <el-input
          v-model.trim="formInline.resourceNo"
          v-check-permission
          placeholder="请输入订单号"
        />
      </el-form-item>
      <!-- </el-col>
        <el-col :span="6"> -->
      <el-form-item label="咨询问题：">
        <el-input
          v-model.trim="formInline.consultationContent"
          v-check-permission
          placeholder="请输入咨询问题"
        />
      </el-form-item>
      <!-- </el-col>
      </el-row>
      <el-row>
        <el-col :span="12"> -->
      <!-- <el-form-item label="咨询时间：">
        <el-date-picker
          v-model="formInline.consultationTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        />
      </el-form-item> -->
      <!-- </el-col>
        <el-col :span="6"> -->
      <el-form-item label="咨询状态：">
        <el-select v-model="formInline.confirmStatus">
          <el-option
            v-for="item in confirmStatusList"
            :key="item.value"
            :value="item.value"
            :label="item.text"
          />
        </el-select>
      </el-form-item>
      <!-- </el-col>
        <el-col :span="6"> -->
      <el-form-item label="手机号：">
        <el-input
          v-model.trim="formInline.patientMobile"
          v-check-permission
          placeholder="请输入手机号"
        />
      </el-form-item>
      <!-- </el-col>
      </el-row>
      <el-row>
        <el-col :span="1">
        &nbsp;
        </el-col>
        <el-col :span="6"> -->
      <el-form-item>
        <el-button type="primary" @click="queryData()"> 查询 </el-button>
        <el-button type="primary" @click="queryClear()"> 重置 </el-button>
      </el-form-item>
      <!-- </el-col>
      </el-row> -->
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column
        prop="resourceNo"
        label="健康咨询订单号"
        align="center"
      />
      <el-table-column prop="patientName" label="患者姓名" align="center" />
      <el-table-column prop="patientMobile" label="手机号" align="center" />
      <el-table-column
        prop="consultationContent"
        label="咨询问题"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="consultationTime"
        label="咨询时间"
        align="center"
      />
      <el-table-column prop="confirmStatus" label="咨询状态">
        <template slot-scope="scope">
          <span>
            {{
              scope.row.confirmStatus
                ? '已抢单'
                : scope.row.cancelTime
                ? '已取消'
                : '未抢单'
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        show-overflow-tooltip
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 0)"
          >
            查看
          </el-button>
          <el-button
            v-if="!scope.row.cancelTime && !scope.row.confirmStatus"
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 1)"
          >
            取消
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog :visible.sync="refundDialogVisible" width="30%">
      <el-form
        v-if="refundDialogVisible"
        ref="form1"
        :model="form1"
        :rules="rules"
        label-width="110px"
        @submit.native.prevent
      >
        <h3 style="margin-bottom: 20px; text-align: center">
          确定{{ cancelOrderNo }}订单取消吗？
        </h3>
        <!-- <h4>确定{{ orderNo }}订单退诊吗？</h4> -->
        <el-form-item label="退诊原因:" prop="cancelReason">
          <el-input
            v-model="form1.cancelReason"
            v-check-permission
            placeholder="请输入退诊原因"
            style="width: 80%"
            maxlength="100"
            @keyup.enter.native="saveService()"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refundDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveService()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { confirmStatusList } from '@/utils/component/publicList';
import moment from 'moment';
export default {
  name: 'ServiceManageList',
  data() {
    return {
      confirmStatusList: confirmStatusList,
      refundDialogVisible: false,
      loading: false,
      formInline: {
        patientName: '',
        consultationContent: '',
        patientMobile: '',
        resourceNo: '',
        confirmStatus: '',
        consultationTime: '',
      },
      cancelOrderNo: '', // 取消健康咨询订单号
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      form1: {
        cancelReason: '',
      },
      rules: {
        cancelReason: [
          { required: true, message: '请输入取消理由', trigger: 'blur' },
        ],
      },
      orderListData: [], // 服务列表
    };
  },
  computed: {
    ...mapState('basicDictionary', {
      occupationList: (state) => state.occupationList,
      professionalList: (state) => state.professionalList,
    }),
  },
  watch: {},
  mounted() {
    this.getHealthConsultationList();
  },
  methods: {
    ...mapActions('orderManage', ['pageQueryResource', 'queryDetail']),

    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getHealthConsultationList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getHealthConsultationList();
    },
    // 重置
    queryClear() {
      this.formInline = {
        // 查询条件
        patientName: '',
        consultationContent: '',
        patientMobile: '',
        resourceNo: '',
        confirmStatus: '',
        consultationTime: '',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getHealthConsultationList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getHealthConsultationList();
    },
    // 分页查询问诊类型列表
    getHealthConsultationList() {
      this.loading = true;
      const params = {
        patientName: this.formInline.patientName,
        consultationContent: this.formInline.consultationContent,
        patientMobile: this.formInline.patientMobile,
        resourceNo: this.formInline.resourceNo,
        confirmStatus: this.formInline.confirmStatus,
        consultationStartTime:
          this.formInline.consultationTime !== '' &&
          this.formInline.consultationTime !== null
            ? moment(this.formInline.consultationTime[0]).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            : '',
        consultationEndTime:
          this.formInline.consultationTime !== '' &&
          this.formInline.consultationTime !== null
            ? moment(this.formInline.consultationTime[1]).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            : '',
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      if (this.formInline.confirmStatus === 'isCancel') {
        params.isCancel = true;
        delete params.confirmStatus;
      }
      this.pageQueryResource(params)
        .then((res) => {
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
          this.loading = false;
        })
        .catch(() => {});
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 编辑
     * 2 - 删除
     */
    dataOperation(item, type) {
      if (type === 0) {
        this.$router.push({
          path: '/orderManage/healthAdvisoryDetail',
          query: {
            detail: JSON.stringify(item),
            type: type,
          },
        });
      } else {
        this.refundDialogVisible = true;
        this.cancelOrderNo = item.resourceNo;
      }
    },
    // 确定取消健康咨询订单
    saveService() {
      const param = {
        ...this.form1,
        resourceNo: this.cancelOrderNo,
      };
      this.$api.cancelResource(param).then((res) => {
        if (res.code === 0) {
          this.$message.success('订单取消成功');
          this.refundDialogVisible = false;
        }
      });
    },
  },
};
</script>
<style lang="css">
.el-table th.gutter {
  display: table-cell !important;
}
</style>
<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
</style>
